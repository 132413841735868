<template>
  <div>
    <LoginModal
      :dialog-visible="showLoginModal"
      @closeModal="isLoginModalClosed"
      @onSetInCart="onSetInCart = $event"
      :set-in-cart="setInCart"
    />
    <Alert
      :alert-visible="showAlert"
      :alert-data="alertData"
      @closeModal="isAlertClosed"
    />
    <v-card class="card">
      <div class="card__img">
        <img @click="showDetails" :src="bundleData.poster" />
      </div>
      <div class="card__content" :class="{ 'd-flex': !isInMySubscriptions }">
        <v-card-title @click="showDetails" class="card__title">
          <span>
            {{ bundleData.name }}
          </span>
        </v-card-title>

        <div class="card__sub">
          <div v-if="bundleData.products_count === 1">مادة واحدة</div>
          <div v-else-if="bundleData.products_count === 2">مادتين</div>

          <div v-else>{{ bundleData.products_count + " مواد" }}</div>
          <div v-if="!isInMySubscriptions" class="price">
            <span v-show="bundleData.hide_price !== true">
              {{ bundleData.price }} ر.س
            </span>
            <br v-if="windowSize < 991" />
            <span
              v-if="
                bundleData.old_price &&
                bundleData.old_price !== bundleData.price
              "
              class="old-price"
              >{{ bundleData.old_price }} ر.س</span
            >
          </div>
        </div>
        <div class="label mt-2" v-if="bundleData.has_newly_added">
          <v-chip class="pa-4" color="#f8cdd1" text-color="#f30e0e">
            مواد مضافة حديثاً
          </v-chip>
        </div>
        <strong
          v-if="
            bundleData.does_has_vat === true &&
            bundleData.hide_price === false &&
            !isInMySubscriptions
          "
          class="text-subtitle-2 mt-2 mr-1 tax-price"
        >
          السعر شامل الضريبة :
          {{ bundleData.price_with_vat_show + " ر.س" }}
        </strong>

        <v-card-actions v-if="!isInMySubscriptions" class="card__btns">
          <v-btn
            :loading="loading"
            @click="
              bundleData.pay_button_title === 'مشترك'
                ? showDetails()
                : addToCart(bundle)
            "
            :disabled="!bundleData.pay_button_status"
            class="add-to-cart"
            height="40"
            elevation="2"
          >
            <img
              :src="`/images/cards/${
                bundleData.pay_button_status &&
                bundleData.pay_button_title !== 'اشترك الآن'
                  ? 'watch'
                  : 'buy'
              }.svg`"
              alt="buy icon"
            />
            {{ bundleData.pay_button_title }}
          </v-btn>
          <div
            class="buttons-wrapper"
            :class="{ 'd-flex align-center': smAndUp }"
          >
            <v-tooltip v-if="bundleData.pay_button_title === 'في السلّة'" top>
              <template v-slot:activator="{ props }">
                <div class="pointer" v-bind="props">
                  <GiftBtn
                    :is-btn-disabled="true"
                    product-type="bundle"
                    :product-data="bundle"
                    @onGift="onGift"
                  />
                </div>
              </template>
              <span v-if="bundleData.pay_button_title === 'في السلّة'"
                >هذه الباقة مضافة للسلة الخاصة بك ، يمكنك اكمال خطوات الإهداء في
                السلة .</span
              >
            </v-tooltip>
            <GiftBtn
              v-else
              product-type="bundle"
              :product-data="bundle"
              @onGift="onGift"
            />
            <FavoriteBtn :item="bundle" @onFavorite="onFavorite" />
          </div>
        </v-card-actions>
        <div v-if="isInMySubscriptions" class="bundle-materials">
          <div class="section-title">
            <img
              src="/images/settings/bundle-materials.svg"
              alt="bundle-materials"
              class="icon"
            />
            <span class="text">المواد المشترك فيها : </span>
          </div>
          <div class="links-container">
            <v-btn
              v-for="(product, i) in bundleData.products"
              :key="i"
              text
              :to="`/materials/${product.slug}`"
              class="course-link"
            >
              {{ product.name }}
            </v-btn>
          </div>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script setup>
import FavoriteBtn from "~/components/course/FavoriteBtn.vue";
import Alert from "~/components/shared/Alert.vue";
import GiftBtn from "~/components/course/GiftBtn";
import LoginModal from "~/components/auth/LoginModal.vue";
import { useCartStore } from "~/stores/CartStore";
import { useAuthStore } from "~/stores/AuthStore";
import { useDisplay } from "vuetify";
import { useBundleStore } from "~/stores/BundleStore";
import { useHomeStore } from "~/stores/HomeStore";
import { ref } from "vue";
const homeStore = useHomeStore();
const bundleStore = useBundleStore();
const { smAndUp } = useDisplay();
const { trackingPurchaseJourney } = useTracking();

const authStore = useAuthStore();
const cartStore = useCartStore();
const props = defineProps({
  bundle: {
    type: Object,
    default: () => ({}),
  },
  isInMySubscriptions: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(["onFavorite"]);

const showLoginModal = ref(false);
const showAlert = ref(false);
const alertData = ref({});
const setBundle = ref({});
const clicked = ref(false);
const loading = ref(false);
const isInCart = ref(false);
const onSetInCart = ref(false);
const setInCart = ref(false);
const cartItems = ref([]);

const bundleData = ref(props.bundle || {});

const router = useRouter();

const isLogin = computed(() => authStore.auth.isLoggedIn);
const cartList = computed(() => cartStore.cartList);
const user = computed(() => authStore.auth.user);

watch(onSetInCart, () => {
  if (onSetInCart.value === true && setBundle.value) {
    setTimeout(() => {
      if (isLogin.value) {
        checkIsInCart(setBundle.value);
      }
    }, 2000);
    onSetInCart.value = false;
  }
});

watch(
  () => props.bundle,
  (newVal) => {
    bundleData.value = newVal;
  },
  { deep: true },
);

const showDetails = () => {
  navigateTo(`/bundles/${props.bundle.slug}`);
};

const addToCart = async (bundle) => {
  loading.value = true;
  if (authStore.auth.isLoggedIn) {
    await checkIsInCart(bundle);
  } else {
    setTimeout(() => {
      loading.value = false;
    }, 500);

    if (localStorage) {
      const ref = useCookie("ref");
      const promo = useCookie("promo");

      let bundlesInLocalStorage =
        JSON.parse(localStorage.getItem("itemsInLocalStorage")) || [];
      const itemData = {
        id: bundleData.value.id,
        type: "bundle",
        ref: ref.value,
        promo_code: promo.value,
      };
      
      if (
        !bundlesInLocalStorage.some(
          (item) => item.id === bundle.id && item.type === "bundle",
        )
      ) {
        bundlesInLocalStorage.push(itemData);
      }
      localStorage.setItem(
        "itemsInLocalStorage",
        JSON.stringify(bundlesInLocalStorage),
      );
      if (!cartStore.cartList) {
        cartStore.cartList = [];
      }
      cartStore.cartList.push(bundle);

      setTimeout(() => {
        loading.value = false;
      }, 500);

      emit("onAddToCart", bundle.id);

      bundleData.value.pay_button_title = "في السلّة";
      bundleData.value.pay_button_status = false;

      setAlertData({
        message: "تمت إضافة المنتج للسلة بنجاح",
        success: true,
      });
    }
  }
};

const checkIsInCart = async (bundle) => {
  await cartStore
    .getCartDetails()
    .then(async (res) => {
      cartItems.value = res.data.cart_items;
      isInCart.value = cartItems.value.find((ele) => ele.id === bundle.id);

      if (!isInCart.value) {
        const formData = new FormData();
        formData.append("product_id", bundle.id);
        formData.append("product_type", "bundle");

        const ref = useCookie("ref");
        const promo = useCookie("promo");

        if (ref.value) {
          formData.append("ref", ref.value);
        }
        if (promo.value) {
          formData.append("promo_code", promo.value);
        }
        await cartStore.addItemToCart(formData).then(async () => {
          setAlertData({
            message: "تمت إضافة الباقة للسلة بنجاح",
            success: true,
          });
          await homeStore.fetchBundles({
            per_page: 2,
            page: 1,
            sort: "DESC",
            orderBy: "created_at",
          });
          bundleStore.changeItemStatusAfterAddedToCart(bundle.id);
          loading.value = false;
          // Fire AddToCart Event
          trackingPurchaseJourney("viewItemSkipped");
          trackingPurchaseJourney("AddToCart", user.value, bundle);
        });
      }
    })
    .catch((err) => {
      loading.value = false;
      console.error(err);
    });
  setTimeout(() => {
    loading.value = false;
  }, 500);
};

const isLoginModalClosed = (payload) => {
  if (payload.value) {
    showLoginModal.value = false;
    if (payload.showSignup) {
      sinup();
    }
  }
  setInCart.value = false;
};

const sinup = () => {
  navigateTo("/auth/signup");
};

const onGift = (responseData) => {
  setAlertData(responseData);
};

const onFavorite = () => {
  emit("onFavorite", { value: true });
};

const setAlertData = (data) => {
  alertData.value = data;
  showAlert.value = true;
};

const isAlertClosed = (payload) => {
  if (payload.value) {
    showAlert.value = false;
  }
};

onMounted(() => {
  if (isLogin.value) {
    const bundlesInLocalStorage =
      JSON.parse(localStorage.getItem("itemsInLocalStorage")) || [];

    const found = bundlesInLocalStorage.find(
      (bundleInLS) => bundleInLS.id === bundleData.value.id,
    );
    if (found) {
      bundleData.value.pay_button_title = "في السلّة";
      bundleData.value.pay_button_status = false;
      if (!cartStore.cartList) {
        cartStore.cartList = [];
      }
      cartStore.cartList.push(bundleData);
    }
  }
});
</script>

<script>
import windowSize from "~/mixins/window-size";
import videoLength from "~/mixins/video-length";
import gtmEvents from "~/mixins/gtm-events";
import { useHomeStore } from "~/stores/HomeStore";
export default {
  mixins: [windowSize, videoLength, gtmEvents],
};
</script>

<style lang="scss" scoped>
.card {
  border-radius: 30px;
  width: 100%;
  height: 250px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  * {
    word-break: break-word !important;
  }
  .card__img {
    width: 40% !important;
    height: 100% !important;
    @media (min-width: 750px) and (max-width: 1300px) {
      height: 260px;
    }
    // @media (min-width:1300px)  {
    //   height: 320px;
    // }
    img {
      cursor: pointer;
      width: 100%;
      height: 100%;
      border-radius: 0 20px 20px 0 !important;
    }
  }
  .card__content {
    // display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 12px;
    width: 60%;
    .card__title {
      cursor: pointer;
      display: flex;
      color: #000;
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.04;
      height: 70px;
      padding: 0px;
      word-break: break-word !important;
      span {
        text-overflow: ellipsis !important;
        // word-break: break-all !important;
        // white-space: nowrap;
      }
    }
    .card__sub {
      display: flex;
      justify-content: flex-start;
      font-size: 16px;
      font-weight: bold;
      color: #000;

      div {
        margin: 0px;
      }
    }
    .tax-price {
      height: 44px;
    }
    .price {
      position: relative;
      margin-right: 30px !important;
      &:before {
        content: "";
        width: 7px;
        height: 8px;
        margin: 0px;
        background-color: #bcbcbc;
        border-radius: 50%;
        position: absolute;
        right: -21px;
        top: 9px;
      }
      .old-price {
        font-size: 10px;
        color: #fa9237;
        text-decoration: line-through;
        margin-right: 10px;
      }
    }
    .label {
      display: flex;
      font-weight: bold;
    }
  }
  .card__btns {
    justify-content: space-between;
    padding: 0px 8px;
    // margin-top: 5px;
    .add-to-cart {
      padding: 24px 20px;
      border-radius: 18px;
      background-color: #f4f4f4;
      color: #000;
      font-size: 18px;
      font-weight: bold;
      img {
        margin-left: 5px;
      }
    }
  }
  .card__des {
    font-size: 15px;
    font-weight: normal;
    line-height: 1.6;
    text-align: right;
    color: #000;
    min-height: 90px;
  }
}
@media (max-width: 1264px) {
  .card {
    min-height: 200px;
    .card__content {
      .card__title {
        font-size: 22px;
        min-height: max-content;
      }
      .card__sub {
        font-size: 14px;
        .price {
          margin-right: 20px !important;
          &:before {
            top: 6px;
            right: -14px;
          }
        }
      }
      // .card__des {
      //   min-height: 105px;
      // }
    }
  }
}
@media (max-width: 991px) {
  .card {
    min-height: 200px;
    .card__content {
      padding: 10px 10px 10px 5px;
      .card__title {
        font-size: 20px;
        min-height: max-content;
        padding: 0;
      }
      .card__sub {
        align-items: flex-start;
        padding: 0;
        font-size: 13px;
        div {
          margin: 0px;
        }
        .price {
          margin-right: 20px !important;
          &:before {
            top: 6px;
            right: -13px;
          }
        }
      }
      .card__btns {
        padding: 0;
        .add-to-cart {
          padding: 22px 10px;
          border-radius: 10px;
          font-size: 14px;
        }
      }
      .v-card__actions {
        padding: 0px 8px;
      }
    }
  }
}

@media (max-width: 960px) {
  @media (min-width: 600px) and (max-width: 808px) {
    .card {
      min-height: 280px;
    }
    .card__btns {
      @media (min-width: 600px) and (max-width: 708px) {
        position: relative;
        left: 14px;
      }
    }
  }
  .card__btns {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .buttons-wrapper {
    display: flex;
    gap: 10px;
    margin-top: 12px;
  }
}
@media (max-width: 600px) {
  .card {
    min-height: 150px;
    .card__content {
      .card__title {
        font-size: 15px;
      }
      .card__sub {
        align-items: flex-start;
        font-size: 12px;
        div {
          margin: 0px 4px 0 6px;
        }
        .price {
          &:before {
            top: 5px;
          }
        }
      }
      // .card__des {
      //   font-size: 10px;
      // }
      .price {
        margin-right: 20px !important;
        .old-price {
          font-size: 10px;
          margin-right: 0px;
          margin-bottom: -10px;
        }
      }
      .card__btns {
        padding: 0 0px;
        .add-to-cart {
          padding: 19px 10px;
          font-size: 14px;
          border-radius: 10px;
        }
        .buttons-wrapper {
          display: flex;
          gap: 10px;
          margin-top: 12px;
        }
      }
      .v-card__actions {
        padding: 0px 8px;
      }
    }
  }
}

.bundle-materials {
  .links-container {
    height: 92px;
    overflow-y: auto;

    .course-link {
      margin: 3px 0 7px 15px !important;
      padding: 2px 16px !important;
      border-radius: 8px !important;
      border: solid 1px #c5c5c5 !important;
      background-color: #f6f6f6 !important;
      cursor: pointer !important;
      &:hover {
        color: #fff !important;
        margin: 3px 0 7px 15px !important;
        padding: 2px 16px !important;
        border-radius: 8px !important;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16) !important;
        border: solid 1px #c5c5c5 !important;
        background-color: #464688 !important;
      }
    }
  }
  .section-title {
    display: flex;
    gap: 5px;
    margin: 10px 0;
    font-weight: bold;
  }
}
</style>
